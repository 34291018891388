import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsFilterModule } from '@design-system/components/filter';
import { DsFilterInputModule } from '@design-system/components/filter-input';
import {
  DsTableLoadingModule,
  DsTableModule,
} from '@design-system/components/table';
import {
  DsTableSettingsModule,
  TableSettingsIntl,
} from '@design-system/components/table-settings';
import { TranslateModule } from '@ngx-translate/core';
import { TableSettingsI18n } from './i18n/table-settings-i18n';

import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';

import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { DsSnackbarComponent } from '@design-system/feature/snackbar';
import { TranslateService } from '@ngx-translate/core';

import { DsLoadingModule } from '@design-system/components/loading';
import { Angulartics2Module } from 'angulartics2';
import { GoogleAnalyticsClickListenerDirective } from '@shared-lib/google-analytics';

export function getTableSettingsIntl(translateService: TranslateService) {
  return new TableSettingsI18n(translateService).getIntl();
}

const sharedModules = [
  // Core modules
  ReactiveFormsModule,
  FormsModule,
  FlexLayoutModule,
  TranslateModule,
  Angulartics2Module,
  // Mat modules
  MatButtonModule,
  MatIconModule,
  MatTableModule,
  MatTooltipModule,
  MatMenuModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  // DS modules
  DsSpacingModule,
  DsFilterModule,
  DsFilterInputModule,
  DsTableModule,
  DsTableLoadingModule,
  DsSnackbarComponent,
  DsLoadingModule,
  DsTableSettingsModule,
  GoogleAnalyticsClickListenerDirective,
];
@NgModule({
  declarations: [],
  imports: sharedModules,
  exports: [...sharedModules],
  providers: [
    {
      provide: TableSettingsIntl,
      deps: [TranslateService],
      useFactory: getTableSettingsIntl,
    },
  ],
})
export class SharedModule {}
